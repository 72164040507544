import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from './tooltip';

const getBackgroundAndBorderColor = value => {
  switch (value) {
    case 4:
      return 'bg-red-500';
    case 3:
      return 'bg-yellow-500';
    case 2:
      return 'bg-yellow-300';
    case 1:
      return 'bg-green-700';
    default:
      return 'bg-green-500';
  }
};

const getColor = value => {
  switch (value) {
    case 4:
      return 'text-red-500';
    case 3:
      return 'text-yellow-500';
    case 2:
      return 'text-yellow-300';
    case 1:
      return 'text-green-700';
    default:
      return 'text-green-500';
  }
};

const getLabel = value => {
  switch (value) {
    case 4:
      return 'Crítica';
    case 3:
      return 'Alta';
    case 2:
      return 'Media';
    case 1:
      return 'Baja';
    default:
      return 'Ninguna';
  }
};

const severityArray = [...Array(4).keys()];

const Severity = ({ value }) => {
  return (
    <div className="flex items-center text-xs space-x-1">
      <strong>Severidad:</strong>
      <div title={getLabel(value)} className="flex flex-col-reverse rounded-sm border">
        {severityArray.map((_, index) => (
          <div
            key={index}
            className={`${
              index < value
                ? getBackgroundAndBorderColor(value)
                : `bg-gray-50 border-gray-100 ${0 < index ? 'border-b' : ''}`
            } h-1 w-3`}
          ></div>
        ))}
      </div>
    </div>
  );
};

Severity.propTypes = {
  value: PropTypes.number,
};

export default Severity;
