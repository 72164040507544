import React, { useContext } from 'react';
import { Link } from 'gatsby';
import Image from 'gatsby-image';
import { ThemeContext } from '../utils';
import Severity from './severity';

const PostCard = ({ post, hideImage = false }) => {
  const [theme] = useContext(ThemeContext);
  const title = post.frontmatter.title || post.fields.slug;
  return (
    <article className={`duration-300 items-center transition-colors w-full flex flex-row border-b ${theme.text}`}>
      {!hideImage && post.frontmatter.featuredImage && (
        <Link className="hidden w-40 mx-3 no-underline cursor-pointer md:block" to={`/advisories${post.fields.slug}`}>
          <Image className="rounded rounded-b-none h-40" fluid={post.frontmatter.featuredImage.childImageSharp.fluid} />
        </Link>
      )}
      <div className="w-full flex flex-col">
        <header className="px-3">
          <h3 className="font-bold tracking-normal text-xl my-3">
            <Link className="no-underline cursor-pointer" to={`/advisories${post.fields.slug}`}>
              {title}
            </Link>
          </h3>
        </header>
        <ul className="px-3 -mt-3 pb-3 text-xs flex space-x-1 list-outside list-none ml-0">
          {post.frontmatter.tags &&
            post.frontmatter.tags.map(tag => (
              <li key={tag} className="text-teal-500">
                {tag}
              </li>
            ))}
        </ul>
        <section className="px-3 flex-1">
          <p
            dangerouslySetInnerHTML={{
              __html: post.frontmatter.description || post.excerpt,
            }}
          />
        </section>
        <div className="px-3 mt-3">
          <Severity value={post.frontmatter.severity} />
        </div>
        <div className="px-3 pb-4 flex-shrink-0">
          <small className="text-gray-400">{post.frontmatter.date}</small>
        </div>
      </div>
    </article>
  );
};

export default PostCard;
