import React, { useContext } from 'react';
import { ThemeContext } from '../utils';
import { Link } from 'gatsby';
import PostCard from './postCard';

const LastPosts = ({ posts, title, description, linked }) => {
  const [theme] = useContext(ThemeContext);
  const pageTitle = title || 'Avisos de seguridad';
  return (
    <div className={`duration-300 transition-colors py-10 px-3 ${theme.text}`}>
      <h2
        className={`duration-300 transition-colors text-center text-3xl font-bold tracking-tight ${theme.textStrong}`}
      >
        {linked && (
          <Link className="no-underline" to="/advisories">
            {pageTitle}
          </Link>
        )}
        {!linked && pageTitle}
      </h2>
      <p
        className={`duration-300 transition-colors my-3 text-center text-xl leading-tight font-light ${theme.textLight}`}
      >
        {title !== null || title !== undefined ? description : ''}
      </p>
      <div className="mt-6 grid gap-y-6 gap-x-12 max-w-6xl mx-auto">
        {posts.map(({ node }) => (
          <PostCard hideImage key={node.fields.slug} post={node} />
        ))}
      </div>
    </div>
  );
};

export default LastPosts;
